import { fetchCollectionProducts } from 'api/product';
import cn from 'classnames';
import Headline from 'components/headline';
import { fetchContentfulByHandle } from 'connectors/contentful';
import useSWR, { SWRConfiguration } from 'swr';
import { CollectionProvider } from '../../state';
import ProductsGrid from '../products-grid';
import ResultCounter from '../result-counter';

interface StandaloneCDPProps {
  children?: React.ReactNode;
  className?: string;
  collectionHandle: string;
  customButton?: React.ReactNode;
  productsCardWhiteBackground?: boolean;
  productsLimit?: number;
  showFilter?: boolean;
  showMoreButton?: boolean;
  subTitle?: string;
  swrConfig?: SWRConfiguration;
  title: string;
  trackingElementName?: string;
}

/**
 * Component that wrap a products grid with a collection provider and the logic to fetch the collection data
 * and the related contentful data based on the collection handle.
 * Children are rendered before the products grid and className is applied to the root div.
 */
const StandaloneCDP: React.FC<StandaloneCDPProps> = ({
  children,
  className,
  collectionHandle,
  customButton,
  productsCardWhiteBackground,
  productsLimit,
  showFilter = true,
  showMoreButton,
  subTitle,
  swrConfig = {},
  title,
  trackingElementName,
}) => {
  const contentfulUrl = `collections/${collectionHandle}`;

  const { data, error } = useSWR(
    [collectionHandle, contentfulUrl],
    (collectionUrl, contentfulUrl) =>
      Promise.all([
        fetchCollectionProducts(collectionUrl, true),
        fetchContentfulByHandle(contentfulUrl),
      ]).then(([collection, contentful]) => {
        return {
          collection,
          contentful,
        };
      }),
    { revalidateOnFocus: false, ...swrConfig },
  );

  if (error || !data?.collection || !data?.contentful) return null;

  return (
    <CollectionProvider
      // Tags is not used in standalone CDP
      tags={[]}
      collectionHandle={collectionHandle}
      collection={data.collection}
    >
      <div className={cn(className)}>
        {children}
        <Headline
          classNames="lg:mb-5 mb-1.5"
          shouldBeH1={false}
          subTitle={subTitle}
          title={title}
          useMindsetFont
          extraElement={
            <span className="hidden lg:block">
              <ResultCounter />
            </span>
          }
        />
        <ProductsGrid
          contentfulData={data.contentful}
          customButton={customButton}
          productsCardWhiteBackground={productsCardWhiteBackground}
          productsLimit={productsLimit}
          showFilter={showFilter}
          showMoreButton={showMoreButton}
          trackingElementName={trackingElementName}
        />
      </div>
    </CollectionProvider>
  );
};

export default StandaloneCDP;
