import React from 'react';

const Canonical = (url: string | null | undefined, omitSlash = false) => {
  let pageUrl: string | undefined;
  if (omitSlash) {
    pageUrl = `${process.env.FRONTEND_URL}${url}`;
  } else {
    pageUrl = `${process.env.FRONTEND_URL}/${url}`;
  }
  return (
    <>
      <link rel="canonical" href={pageUrl} />
      <meta name="og:url" property="og:url" content={pageUrl} />
    </>
  );
};

export default Canonical;
