import useImpressionTracking, {
  useImpressionTrackingProps,
} from 'hooks/gtm/use-impression-tracking';
import React, {
  Children,
  cloneElement,
  isValidElement,
  useMemo,
  useRef,
} from 'react';
import { gtm, viewElement } from 'tracking';
import { slugify, toCamelCase } from 'utils/strings';

export interface ImpressionTrackerProps {
  /** The name of the tracked element, e.g. ["Mein Hund Ist sehr waehlerisch"] */
  element: string | string[];
  /** The name of the list that the tracked element belongs to */
  list?: string;
  /** useImpressionTrackingProps */
  options?: useImpressionTrackingProps['options'];
  /** Custom event */
  customEvent?: () => void;
}

/**
 * New Impression Tracker. After migration, the component name should be changed to ImpressionTracker
 * Automatically track impression of child.
 * if not specified, Component event are used.
 * Child should be wrapped with div or other tags so that tracker works properly
 */
const ImpressionTrackerNew: React.FC<
  React.PropsWithChildren<ImpressionTrackerProps>
> = ({
  children,
  element,
  list,
  options = { threshold: 0.9 },
  customEvent,
}) => {
  const ref = useRef(null);
  const impressionId = useMemo(
    () => (typeof element === 'string' ? element : element.join('#')),
    [element]
  );

  useImpressionTracking({
    impressionStoreKey:
      typeof element === 'string'
        ? toCamelCase(element)
        : toCamelCase(element[0]),
    impressionId: slugify(impressionId),
    trackingFn: (isInViewport: boolean) => {
      if (isInViewport) {
        customEvent
          ? customEvent()
          : gtm(
              viewElement({
                element,
                ...(list && { list }),
              })
            );
      }
    },
    ref,
    options,
  });

  const child = Children.only(children);

  if (isValidElement<any>(child)) {
    return cloneElement(child, { ref });
  }

  return <>{children}</>;
};

export default ImpressionTrackerNew;
