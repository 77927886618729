import useIsClient from 'hooks/common/use-is-client';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

interface OneLineTextProps {
  initialFontSize: number;
  isDisabled?: boolean;
}

/**
 * Adjust text font size to fit in one line by checking the width of container.
 * Useful when there's a tight demand for first viewport
 * @author : almost by Chat GPT
 */
const OneLineText: React.FC<React.PropsWithChildren<OneLineTextProps>> = ({
  initialFontSize,
  isDisabled = false,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [fontSize, setFontSize] = useState(initialFontSize); // Set an initial font size, you can adjust this as per your needs

  useEffect(() => {
    setFontSize(initialFontSize);
  }, [initialFontSize]);

  const { isClient } = useIsClient();

  useEffect(() => {
    if (isDisabled) return;
    // Function to calculate and set the appropriate font size
    const adjustFontSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const textWidth = containerRef.current.scrollWidth;
        const availableWidth = containerWidth; // You can adjust this padding value as needed

        if (textWidth > availableWidth) {
          // Calculate the new font size based on the available width
          const newFontSize = Math.floor(
            (availableWidth / textWidth) * fontSize
          );
          setFontSize(newFontSize);
        }
      }
    };
    const debounced = debounce(adjustFontSize, 500, { trailing: true });

    // Call the adjustFontSize function when the component mounts and whenever it resizes
    debounced();
    window.addEventListener('resize', debounced);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', debounced);
    };
  }, [fontSize, isDisabled, initialFontSize]);

  if (!isClient) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontSize: `${fontSize}px`,
      }}
    >
      {children}
    </div>
  );
};

export default OneLineText;
