import React from 'react';

const Image = (image?: string) => (
  <>
    <meta
      name="twitter:image:src"
      property="twitter:image:src"
      content={image || 'https://www.petsdeli.de/images/share-catdog.jpg'}
    />
    <meta
      name="og:image"
      property="og:image"
      content={image || 'https://www.petsdeli.de/images/share-catdog.jpg'}
    />
    <meta
      name="og:image:secure_url"
      property="og:image"
      content={image || 'https://www.petsdeli.de/images/share-catdog.jpg'}
    />
  </>
);

export default Image;
