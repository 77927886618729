import cn from 'classnames';
import ImpressionTracker from 'components/impression-tracker';
import OneLineText from 'components/one-line-text';
import RibbonText from 'components/ribbon-text';
import { usePetContext } from 'contexts/pet';
import useMobile from 'hooks/common/use-mobile';
import isArray from 'lodash/isArray';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import CollectionSectionBestseller from '../collection-section-bestseller';

interface InfluencerCollectionSliderProps {
  className?: string;
}

/**
 * This component is used to show a slider of products that an influencer has promoted.
 */
const InfluencerCollectionSlider: React.FC<InfluencerCollectionSliderProps> = ({
  className,
}) => {
  const { isReady, query } = useRouter();
  const { current } = usePetContext();
  const isMobile = useMobile();

  const utmCampaign = isArray(query.utm_campaign)
    ? query.utm_campaign[0]
    : query.utm_campaign;
  const collectionHandle = isArray(query.collection)
    ? query.collection[0]
    : query.collection;

  const influencerName = useMemo(() => {
    if (!isReady || !utmCampaign) return null;
    const splitString = utmCampaign.split('_');
    const _influencerName = splitString.slice(1).join('_');
    return _influencerName;
  }, [isReady, utmCampaign]);

  const lastSlide = (
    <Link href={`/collections/${collectionHandle}`} passHref>
      <div className="flex pb-4">
        <div className="flex w-full flex-col justify-center rounded-lg bg-brand-primary px-8">
          <p className="flex h-18 items-center justify-center rounded-lg bg-white px-3 text-center text-base font-black leading-tight text-brand-primary">
            <FormattedMessage id="landingpage:influencer-slider:cta-button" />
          </p>
        </div>
      </div>
    </Link>
  );

  if (!influencerName || !collectionHandle) return null;

  return (
    <ImpressionTracker
      trackingGroupName="influencer-collection-slider"
      trackingLabel="Influencer Collection Slider"
    >
      <div
        className={cn(
          'container mb-2 mt-4 px-2 lg:mb-4 lg:mt-8 lg:px-8',
          className
        )}
      >
        <span className="font-mindset-default text-brand-primary">
          <OneLineText
            initialFontSize={isMobile ? 24 : 40}
            isDisabled={!isMobile}
          >
            <FormattedMessage
              id="landingpage:influencer-slider:title"
              values={{
                name: influencerName,
                RibbonText: (chunks) => (
                  <RibbonText color={current === 'dogs' ? 'soft-cyan' : 'pink'}>
                    {chunks}
                  </RibbonText>
                ),
              }}
            />
          </OneLineText>
        </span>
        <div className="mt-4 overflow-hidden">
          <CollectionSectionBestseller
            collection={collectionHandle}
            // To show only the first 5 products in the collection, we use this property that we already have. If the test is successful,
            // we should allign this property with a better name.
            isBlogPost
            lastSlide={lastSlide}
            shouldShowLoading={true}
            // we need to pass down this option so that first render can provide collection
            swrOptions={{ revalidateOnMount: true }}
          />
        </div>
      </div>
    </ImpressionTracker>
  );
};

export default InfluencerCollectionSlider;
