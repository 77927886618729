import NotFound from 'components/not-found-page';
import TranslationsProvider from 'contexts/translations';
import { NextPage } from 'next';
import React from 'react';

const Error: NextPage = () => {
  return (
    <TranslationsProvider>
      <NotFound />
    </TranslationsProvider>
  );
};

export default Error;
