import cn from 'classnames';
import ImpressionTrackerNew from 'components/impression-tracker/new-impression-tracker';
import LazyMotionWrapper from 'components/lazy-motion-wrapper';
import NavItem from 'components/nav-item';
import {
  CatNutritionSegmentationIds,
  isCatSegmentation,
  isNutritionSegmentation,
  NutritionSegmentationIds,
  Segmentation,
} from 'constants/segmentation';
import {
  SegmentationLocation,
  useSegmentationContext,
} from 'contexts/segmentation';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { gtm, selectElement } from 'tracking';

interface SegmentationDropDownProps {
  onSelectCallback?: () => void;
  isOpen?: boolean;
  location: SegmentationLocation;
  petType?: 'dog' | 'cat';
}

/**
 * Pet need segmentation pull down
 */
const PetNeedsSegmentationDropDown: React.FC<SegmentationDropDownProps> = ({
  onSelectCallback,
  isOpen = true,
  location,
  petType = 'dog',
}) => {
  const intl = useIntl();
  const { push } = useRouter();
  const { segmentationData, pushSegmentationFactory } =
    useSegmentationContext();
  const pushSegmentation = pushSegmentationFactory({ location });

  const options = useMemo(() => {
    let segmentation: Array<Segmentation> = [];
    if (petType === 'cat') {
      segmentation = segmentationData.segmentation
        .filter((v) => isCatSegmentation(v.id))
        .filter((v) => v.id !== CatNutritionSegmentationIds.Noneedcat);
    } else {
      segmentation = segmentationData.segmentation
        .filter((v) => !isCatSegmentation(v.id))
        .filter(
          (v) =>
            v.id !== NutritionSegmentationIds.Foodaid &&
            v.id !== NutritionSegmentationIds.Disease &&
            v.id !== NutritionSegmentationIds.NoHelpNeeded
        );
    }
    return segmentation
      .filter((v) => isNutritionSegmentation(v.id))
      .map((v) => ({
        // @ts-expect-error : 2nd argument type is not correct
        label: intl.formatMessage(
          {
            id: `landingpage:segmentation-pulldown:${v.id}`,
          },
          {
            b: (chunks) => <b>{chunks}</b>,
          }
        ),
        value: v.id,
      }));
  }, [petType, segmentationData.segmentation, intl]);

  const onSelect = async (value: string): Promise<void> => {
    // Send segmentation
    await pushSegmentation({ segmentation: value });
    if (onSelectCallback) {
      onSelectCallback();
    }

    // Redirect to cdp
    const segmentData = segmentationData?.segmentation.find(
      (v) => v.id === value
    );
    if (segmentData) {
      push(segmentData.redirectPages[0]);
      gtm(
        selectElement({
          element: ['Pet Need Options', segmentData.id],
        })
      );
    } else {
      console.warn('wrong usage');
    }
  };

  return (
    <LazyMotionWrapper>
      {/* // should be better animatino if I could  */}
      {isOpen && (
        <ImpressionTrackerNew element="Pet Need Options">
          <>
            <div>
              {options.map(({ value, label }) => (
                <NavItem
                  key={value}
                  className={cn(' mb-3 w-full bg-white last:mb-0 lg:mb-[15px]')}
                  onClick={() => {
                    onSelect(value);
                  }}
                >
                  {label}
                </NavItem>
              ))}
            </div>
          </>
        </ImpressionTrackerNew>
      )}
    </LazyMotionWrapper>
  );
};

export default PetNeedsSegmentationDropDown;
