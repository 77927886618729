import ModalSlideUp from 'components/modal-slide-up';
import Link from 'next/link';

interface ModalInformationProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Modal for information about the segmentation
 */
const ModalInformation: React.FC<ModalInformationProps> = ({
  isOpen,
  setIsOpen,
}) => {
  return (
    <ModalSlideUp
      open={isOpen}
      onClose={() => setIsOpen(false)}
      title="Wann wähle ich was?"
      titleClassName="text-brand-primary"
    >
      <p className="mb-2">
        Wenn dein Hund <b>ein sehr klar erkennbares Problem</b> oder Bedürfnis
        hat: wähle Bedürfnis anstatt eine Rasse.
      </p>
      <p className="mb-2">
        Bist du dir unsicher, wähle die passende Rasse aus.
      </p>
      <p>
        Hat dein Hund kein spezielles Bedürfnis, die passende Rasse ist nicht
        vorhanden oder du möchtest alle unsere Produkte kennenlernen,{' '}
        <Link href="/collections/fur-hunde-alle-fleischsorten">
          <a className="cursor-pointer underline">klicke hier</a>
        </Link>
      </p>
    </ModalSlideUp>
  );
};

export default ModalInformation;
