import title from 'components/seo/title';
import { NextPage } from 'next';
import Head from 'next/head';
import NextLink from 'next/link';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const NotFound: NextPage = () => {
  const intl = useIntl();

  return (
    <>
      <Head>
        {title(
          intl.formatMessage({
            id: 'error:page:page-title:page-not-found',
          })
        )}
      </Head>
      <div
        className="container mb-10 flex flex-col items-center justify-center py-10"
        style={{ minHeight: '40vh' }}
      >
        <div className="flex flex-col">
          <NextLink href="/">
            <a aria-label="Pets Deli" className="mb-4 mr-auto lg:mr-0">
              <img
                src="/images/petsdeli-logo.svg"
                className="w-auto"
                alt="Pets Deli"
                role="presentation"
              />
            </a>
          </NextLink>

          <h1 className="text-3xl font-black text-brand-primary ">
            <FormattedMessage id="error:page:header" />
          </h1>
          <p className="py-4 text-brand-primary">
            <FormattedMessage id="error:page:suggestions:header" />
          </p>
          <NextLink href="/">
            <a className="mb-3 text-brand-blue-neu underline">
              <FormattedMessage id="error:page:suggestions:home" />
            </a>
          </NextLink>
          <NextLink href={`/pages/${process.env.CONSULTING}`}>
            <a className="mb-3 text-brand-blue-neu underline">
              <FormattedMessage id="error:page:suggestions:foodaid" />
            </a>
          </NextLink>
          <NextLink href="/account">
            <a className="mb-3 text-brand-blue-neu underline">
              <FormattedMessage id="error:page:suggestions:account" />
            </a>
          </NextLink>

          <NextLink href="/cart">
            <a className="mb-3 text-brand-blue-neu underline">
              <FormattedMessage id="error:page:suggestions:cart" />
            </a>
          </NextLink>
        </div>
      </div>
    </>
  );
};

export default NotFound;
