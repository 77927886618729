import cn from 'classnames';
import ImpressionTrackerNew from 'components/impression-tracker/new-impression-tracker';
import NavItem from 'components/nav-item';
import {
  BreedSegmentationIds,
  isNutritionSegmentation,
} from 'constants/segmentation';
import {
  SegmentationLocation,
  useSegmentationContext,
} from 'contexts/segmentation';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { gtm, selectElement } from 'tracking';
import useScrollTracking from 'tracking/use-scroll-tracking';
import styles from './breed-segmentation-slider.module.css';

interface BreedSegmentationSliderProps {
  className?: string;
  location: SegmentationLocation;
  noRedirect?: boolean;
  petType?: 'cat' | 'dog';
  showAsSelectButton?: boolean;
  onSelectCallback?: () => void;
}

const BREED_IMAGE_MAP = {
  [BreedSegmentationIds.Bulldog]:
    '/images/breed/230721_pd_home_breed-slider-bulldogs-100x120px.png',
  [BreedSegmentationIds.Chihuahua]:
    '/images/breed/230721_pd_home_breed-slider-chihuahua-100x120px.png',
  [BreedSegmentationIds.Havanese]:
    '/images/breed/230721_pd_home_breed-slider-maltese-100x120px.png',
  [BreedSegmentationIds.OtherOrMixed]:
    '/images/breed/230721_pd_home_breed-slider-mixed-100x120px.png',
  [BreedSegmentationIds.Poodle]:
    '/images/breed/230721_pd_home_breed-slider-poodle-100x120px.png',
  [BreedSegmentationIds.Retriever]:
    '/images/breed/230721_pd_home_breed-slider-retriever-100x120px.png',
  [BreedSegmentationIds.ShepherdDog]:
    '/images/breed/230721_pd_home_breed-slider-shepard-100x120px.png',
  [BreedSegmentationIds.Spitz]:
    '/images/breed/230721_pd_home_breed-slider-spitz-100x120px.png',
  [BreedSegmentationIds.Terrier]:
    '/images/breed/230721_pd_home_breed-slider-terrier-100x120px.png',
  [BreedSegmentationIds.Dachshund]:
    '/images/breed/230721_pd_home_breed-slider-wiederdog-100x120px.png',
  [BreedSegmentationIds.Herdingdog]:
    '/images/breed/230721_pd_home_breed-slider-herdingdog-100x120px.png',
};

const COLOR_SCHEME = [
  'blue',
  'orange',
  'green',
  'yellow',
  'purple',
  'turquoise',
  'pink',
];

const SOFT_COLOR_SCHEME = [
  '#98D2F4',
  '#F7B77A',
  '#46AA9B',
  '#F7D07A',
  '#E2ABCF',
  '#65C1BE',
  '#FFC7C6',
];

function getColorIndex(index: number): number {
  if (index < COLOR_SCHEME.length) {
    return index;
  }
  return getColorIndex(index - COLOR_SCHEME.length);
}

const BreedSegmentationSlider: React.FC<BreedSegmentationSliderProps> = ({
  className,
  location,
  noRedirect = false,
  petType = 'dog',
  showAsSelectButton = false,
  onSelectCallback,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const { push } = useRouter();
  const { segmentationData, pushSegmentationFactory } =
    useSegmentationContext();
  const pushSegmentation = pushSegmentationFactory({ location });
  const breedOptions = useMemo(() => {
    if (petType === 'cat') {
      return [];
    }
    return segmentationData.segmentation
      .filter((v) => !isNutritionSegmentation(v.id))
      .filter((v) => v.id !== BreedSegmentationIds.OtherOrMixed)
      .map((v) => ({
        label: intl.formatMessage({
          id: `landingpage:breed-slider:breed:${v.id}`,
        }),
        value: v.id,
      }));
  }, [petType, segmentationData.segmentation, intl]);

  const onClick = async (value: string): Promise<void> => {
    // Send segmentation
    await pushSegmentation({ segmentation: value });
    if (onSelectCallback) {
      onSelectCallback();
    }

    // Redirect to cdp
    const segmentData = segmentationData.segmentation.find(
      (v) => v.id === value
    );
    if (segmentData) {
      if (!noRedirect) push(segmentData.redirectPages[0]);
      gtm(
        selectElement({
          element: ['Breed Selector', segmentData.id],
        })
      );
    } else {
      console.warn('wrong usage');
    }
  };

  useScrollTracking(ref, ['Breed Selector']);
  if (!breedOptions.length) {
    return null;
  }

  if (showAsSelectButton) {
    return (
      <ImpressionTrackerNew element="Breed Selector">
        <div>
          {breedOptions.map((breed) => (
            <NavItem
              onClick={() => {
                onClick(breed.value);
              }}
              key={breed.value}
              className={cn(
                'relative mb-3 w-full rounded-md bg-white px-3 py-2.5 text-center text-sm text-gray-600 last:mb-0 lg:mb-[15px]'
              )}
            >
              {breed.label}
            </NavItem>
          ))}
        </div>
      </ImpressionTrackerNew>
    );
  }

  return (
    <ImpressionTrackerNew element="Breed Selector">
      <div>
        <div
          className={cn(
            '-mx-4 flex snap-x snap-mandatory flex-row gap-1.5 overflow-scroll',
            className,
            styles.scrollbarHide
          )}
          ref={ref}
        >
          {breedOptions.map((breed, index) => (
            <button
              onClick={() => {
                onClick(breed.value);
              }}
              key={breed.value}
              className={cn(
                ' scrollbar-hide relative h-[120px] w-[100px] flex-none snap-center rounded-xl border-2 border-white first:ml-4 last:mr-4',
                `bg-new-brand-${COLOR_SCHEME[getColorIndex(index)]}`
              )}
            >
              <Image
                src={BREED_IMAGE_MAP[breed.value]}
                layout="fill"
                objectFit="contain"
                objectPosition="center"
                className="rounded-lg"
                priority={true}
              />
              <div
                className={cn(
                  'absolute bottom-0 w-full rounded-b-lg text-center font-black capitalize ',
                  `bg-[${SOFT_COLOR_SCHEME[getColorIndex(index)]}]`
                )}
              >
                <span className="text-white brightness-100">{breed.label}</span>
              </div>
            </button>
          ))}
        </div>
      </div>
    </ImpressionTrackerNew>
  );
};

export default BreedSegmentationSlider;
