import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';

const Description = (
  description?: string | null | undefined
): ReactElement<HTMLMetaElement> => {
  const intl = useIntl();
  const defaultDescription = intl.formatMessage({
    id: 'seo:default-description',
  });
  return (
    <>
      <meta
        property="description"
        name="description"
        content={description || defaultDescription}
      />
      <meta
        property="twitter:description"
        name="twitter:description"
        content={description || defaultDescription}
      />
      <meta
        property="og:description"
        name="og:description"
        content={description || defaultDescription}
      />
    </>
  );
};

export default Description;
