import { Asset } from 'contentful';
import NextImage, { ImageProps } from 'next/image';
import React, { CSSProperties } from 'react';
import { contentful } from 'utils/image-loader';

interface Props extends Asset, Omit<ImageProps, 'src'> {
  className?: string;
  style?: CSSProperties;
  layout?: 'responsive' | 'fixed' | 'intrinsic' | 'fill';
  objectFit?: 'cover' | 'contain';
  sizes?: string;
}

type Layout = 'responsive' | 'fixed' | 'intrinsic';
type Dimensions = { width: number; height: number };

const Image: React.FC<Props> = ({
  className,
  fields,
  style = {},
  layout,
  objectFit,
  sizes,
  ...others
}) => {
  if (!fields) return null;

  if (fields.file.url.indexOf('.svg') > -1) {
    return (
      <img
        src={fields.file.url}
        style={style}
        className={className}
        alt={fields.title || fields.description || ''}
      />
    );
  }

  const props: Dimensions = {} as Dimensions;

  if (layout !== 'fill') {
    props.width = fields.file.details.image?.width as number;
    props.height = fields.file.details.image?.height as number;
  }

  return (
    <NextImage
      loader={contentful}
      alt={fields.title || fields.description || ''}
      title={fields.title || fields.description || ''}
      className={className}
      src={fields.file.url}
      {...props}
      layout={layout as Layout}
      objectFit={objectFit}
      sizes={sizes}
      {...others}
    />
  );
};

export default Image;
